@import "bootstrap";

html, body{
    min-height: 100%;
}
b, strong{
    font-weight: 700;
}
.form-floating{

    > .form-select~label,
    > .form-control-plaintext~label,
    > .form-control:focus~label,
    > .form-control:not(:placeholder-shown)~label{
        color: $primary;
    }

    > .form-control-plaintext:focus::placeholder,
    > .form-control:focus::placeholder{
        color: $gray-300;
    }
    > :disabled~label::after{
        background-color: var(--bs-secondary-bg) !important;
    }

}

.modal-cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFF;
    z-index: 999;
    border-radius: var(--bs-modal-border-radius);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}