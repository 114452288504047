//Custom Variables
$font-family-sans-serif: "Poppins", sans-serif;

$primary: #2952A3;
$secondary: #008000;

$font-weight-lighter: 100;
$font-weight-bolder: 900;


$btn-padding-x: 1rem;
$btn-padding-y: 0.5rem;

$input-border-width: 2px;

$accordion-button-active-bg: $primary;
$accordion-button-active-color: #FFF;
$accordion-icon-active-color: #FFF;
$accordion-button-focus-border-color: $primary;
$accordion-button-focus-box-shadow: none;

$form-floating-input-padding-t: 1rem;
$form-floating-input-padding-b: 1rem;

$form-valid-color: var(--bs-body-color);
$form-valid-border-color: var(--bs-border-color);
$form-feedback-icon-valid: none;

$input-border-width: 2px;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;
$form-select-focus-box-shadow: none;
$form-floating-label-transform: scale(.85) translateY(-1.75rem) translateX(0.15rem);


// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";